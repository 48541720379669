import { ref } from "vue";

interface Breakpoint {
  length: number
  name: string
}

type CalculateBreakpoint = (width: number) => void

const BREAKPOINTS: Breakpoint[] = [
  {
    length: 0,
    name: 'xs'
  },
  {
    length: 576,
    name: 'sm'
  },
  {
    length: 768,
    name: 'md'
  },
  {
    length: 992,
    name: 'lg'
  },
  {
    length: 1200,
    name: 'xl'
  },
  {
    length: 1600,
    name: 'xxl'
  },
  {
    length: 2559,
    name: 'qhd' // 2K resolution
  },
  {
    length: 3839,
    name: 'uhd' // 4K resolution
  },
].sort((a, b) => b.length - a.length )

const calculateBreakpoint: CalculateBreakpoint = (width) => {
  breakpoint.current.value = breakpoint.breakpoints.find(breakpoint => breakpoint.length < width).name;
}

const breakpoint = {
  current: ref(null),
  breakpoints: BREAKPOINTS,
  less(breakpoint: string) {
    return this.breakpoints.findIndex((bp: Breakpoint) => bp.name === this.current.value) > this.breakpoints.findIndex((bp: Breakpoint) => bp.name === breakpoint)
  },
  lessEquals(breakpoint: string) {
    return this.breakpoints.findIndex((bp: Breakpoint) => bp.name === this.current.value) >= this.breakpoints.findIndex((bp: Breakpoint) => bp.name === breakpoint)
  },
  equals(breakpoint: string) {
    return this.breakpoints.findIndex((bp: Breakpoint) => bp.name === this.current.value) === this.breakpoints.findIndex((bp: Breakpoint) => bp.name === breakpoint)
  },
  greaterEquals(breakpoint: string) {
    return this.breakpoints.findIndex((bp: Breakpoint) => bp.name === this.current.value) <= this.breakpoints.findIndex((bp: Breakpoint) => bp.name === breakpoint)
  },
  greater(breakpoint: string) {
    return this.breakpoints.findIndex((bp: Breakpoint) => bp.name === this.current.value) < this.breakpoints.findIndex((bp: Breakpoint) => bp.name === breakpoint)
  }
};

calculateBreakpoint(window.innerWidth);

window.addEventListener('resize', (event)=> calculateBreakpoint((event.currentTarget as Window).innerWidth))

export default breakpoint;